import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import { confirmSignUp, signIn } from "../services/cognitoService";

const ConfirmSignUpPage: React.FC = () => {
	const [email, setEmail] = useState("");
	const [confirmationCode, setConfirmationCode] = useState("");
	const [password, setPassword] = useState(""); // Add password state
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const email = params.get("email");
		const password = params.get("password"); // Retrieve password from query params
		if (email) {
			setEmail(email);
		}
		if (password) {
			setPassword(password); // Set password state
		}
	}, [location]);

	const handleConfirmSignUp = async () => {
		try {
			await confirmSignUp(email, confirmationCode);
			await signIn(email, password); // Sign in the user immediately after confirmation
			navigate("/terms"); // Navigate to the terms page after successful sign-in
		} catch (err: unknown) {
			if (err instanceof Error) {
				setError(
					err.message ||
						"Failed to confirm signup or sign in. Please check your credentials and try again."
				);
			} else {
				setError("An unexpected error occurred. Please try again.");
			}
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				backgroundColor: "#f5f5f5",
			}}
		>
			<Paper elevation={3} sx={{ p: 4, width: "300px" }}>
				<Typography variant="h5" sx={{ mb: 2 }}>
					Confirm Sign Up
				</Typography>
				<Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
					Please check your email for the confirmation code.
				</Typography>
				<TextField
					label="Email"
					variant="outlined"
					fullWidth
					disabled
					sx={{ mb: 2 }}
					value={email}
				/>
				<TextField
					label="Confirmation Code"
					variant="outlined"
					fullWidth
					sx={{ mb: 2 }}
					value={confirmationCode}
					onChange={(e) => setConfirmationCode(e.target.value)}
				/>
				{error && (
					<Typography color="error" sx={{ mb: 2 }}>
						{error}
					</Typography>
				)}
				<Button
					variant="contained"
					color="primary"
					fullWidth
					onClick={handleConfirmSignUp}
				>
					Confirm
				</Button>
				<Typography variant="body2" sx={{ mt: 2 }}>
					Didn't receive a code? <Link to="/resend-code">Resend code</Link>
				</Typography>
			</Paper>
		</Box>
	);
};

export default ConfirmSignUpPage;
