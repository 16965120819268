import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	Typography,
	Paper,
	Checkbox,
	FormControlLabel,
	Button,
	Box,
} from "@mui/material";

interface TermsAndConditionsPageProps {
	checkAuthAndTerms: () => void;
}

const TermsAndConditionsPage: React.FC<TermsAndConditionsPageProps> = ({
	checkAuthAndTerms,
}) => {
	const [agreed, setAgreed] = useState(false);
	const navigate = useNavigate();

	const handleAgree = () => {
		if (agreed) {
			console.log("User agreed to terms.");
			localStorage.setItem("termsAccepted", "true");
			checkAuthAndTerms(); // Re-check authentication and terms acceptance
			navigate("/");
		}
	};

	const handleDisagree = () => {
		console.log("User disagreed with terms.");
		localStorage.removeItem("termsAccepted");
		navigate("/login");
	};

	return (
		<Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
			<Paper elevation={3} sx={{ p: 4 }}>
				<Typography variant="h4" gutterBottom>
					Terms and Conditions
				</Typography>
				<Typography variant="h5" gutterBottom>
					Section I: Purpose
				</Typography>
				<Typography variant="body1" paragraph>
					The primary purpose of Ask HEMY is to serve as a reliable and
					trustworthy resource for individuals seeking biblical truths from a
					Coptic Orthodox perspective. By leveraging credible sources and
					teachings from the Coptic Orthodox Diocese of the Southern United
					States, under the auspices of His Eminence Metropolitan Youssef, the
					platform aims to support and guide believers in their spiritual growth
					and understanding.
				</Typography>
				<Typography variant="h5" gutterBottom>
					Section II: Acknowledgement of Limitations
				</Typography>
				<Typography variant="body1" paragraph>
					Users are reminded that all information provided by Ask HEMY should be
					discussed with their Pope, Metropolitan, Bishop, or Father of
					Confession. The AI-driven tool has inherent limitations and is
					intended to provide assistance in searching for truths and providing
					starting points of knowledge and understanding for various topics of
					interest to believers. It is crucial to seek pastoral guidance and
					verification for spiritual and doctrinal matters.
				</Typography>
				<Typography variant="h5" gutterBottom>
					Section III: Disclaimer on Medical Advice
				</Typography>
				<Typography variant="body1" paragraph>
					The information provided by Ask HEMY is intended solely for
					educational and informational purposes. It should not be considered as
					medical, mental health, or professional advice.
				</Typography>
				<Typography variant="body1" paragraph>
					If you are experiencing any form of physical or mental health issues,
					we strongly urge you to seek immediate help from a qualified
					healthcare provider or mental health professional. In cases of medical
					emergencies, please contact emergency services or visit the nearest
					hospital.
				</Typography>
				<Typography variant="body1" paragraph>
					Your health and well-being are paramount. While Ask HEMY aims to
					provide valuable insights and information, it is not a substitute for
					professional medical consultation, diagnosis, or treatment. Always
					consult your healthcare provider or mental health professional with
					any questions or concerns you may have regarding your health.
				</Typography>
				<Typography variant="h5" gutterBottom>
					Section IV: Understanding
				</Typography>
				<Typography variant="body1" paragraph>
					By using Ask HEMY, users acknowledge and agree to the following terms
					of use:
				</Typography>
				<Typography variant="body2" paragraph>
					1. Users believe in the Lord Jesus Christ, the Only-Begotten Son of
					God, begotten of the Father before all ages; Light of Light, true God
					of true God, begotten not created, of one essence with the Father, by
					whom all things were made; Who for us men and for our salvation came
					down from heaven, and was incarnate of the Holy Spirit and the Virgin
					Mary and became Man. And He was crucified for us under Pontius Pilate,
					suffered and was buried. And on the third day He rose from the dead,
					according to the scriptures, ascended to the heavens; He sits at the
					right hand of His Father, and He is coming again in His glory to judge
					the living and the dead, Whose kingdom shall have no end.
				</Typography>
				<Typography variant="body2" paragraph>
					2. If you are experiencing any form of physical or mental health
					issues, we strongly urge you to seek immediate help from a qualified
					healthcare provider or mental health professional. In cases of medical
					emergencies, please contact emergency services or visit the nearest
					hospital.
				</Typography>
				<Typography variant="body2" paragraph>
					3. Users acknowledge that the information provided is intended for
					educational and informational purposes only.
				</Typography>
				<Typography variant="body2" paragraph>
					4. Users acknowledge that the information provided may be outdated and
					can be updated at any time.
				</Typography>
				<Typography variant="body2" paragraph>
					5. Users acknowledge that they should always seek additional counsel
					and verification from their Pope, Metropolitan, Bishop, or Father of
					Confession.
				</Typography>
				<Typography variant="body2" paragraph>
					6. Users acknowledge that Ask HEMY is a supplement to, not a
					replacement for, traditional pastoral guidance, spiritual direction,
					and the sacraments of repentance and confession.
				</Typography>
				<Typography variant="body2" paragraph>
					7. Users acknowledge that they can be banned for any reason which
					includes but is not limited to the misuse or any inappropriate usage
					of Ask Hemy, any writings or actions that go against the teachings of
					the Coptic Orthodox Church.
				</Typography>
				<Typography variant="body2" paragraph>
					8. Users are at least age 18 or have permission to use Ask HEMY from
					either their parents or legal guardians.
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							checked={agreed}
							onChange={(e) => setAgreed(e.target.checked)}
						/>
					}
					label="I Agree to the Terms of Use"
					sx={{ color: "#B22222", mb: 2 }}
				/>
				<Box sx={{ textAlign: "center" }}>
					<Button
						variant="contained"
						sx={{
							backgroundColor: "#B22222",
							color: "#FFFFFF",
							"&:hover": {
								backgroundColor: "#8B0000",
							},
							mr: 2,
						}}
						onClick={handleAgree}
						disabled={!agreed}
					>
						Proceed
					</Button>
					<Button
						variant="contained"
						sx={{
							backgroundColor: "#8B0000",
							color: "#FFFFFF",
							"&:hover": {
								backgroundColor: "#B22222",
							},
						}}
						onClick={handleDisagree}
					>
						Disagree
					</Button>
				</Box>
			</Paper>
		</Container>
	);
};

export default TermsAndConditionsPage;
