import React, { useState } from "react";
import axios from "axios";
import {
	Box,
	Button,
	TextField,
	Typography,
	Paper,
	CircularProgress,
} from "@mui/material";

interface ChatBoxProps {
	selectedDirectory: string;
	userEmail: string;
}

const ChatBox: React.FC<ChatBoxProps> = ({ selectedDirectory, userEmail }) => {
	const [query, setQuery] = useState("");
	const [response, setResponse] = useState("");
	const [citations, setCitations] = useState<string[]>([]);
	const [presignedUrls, setPresignedUrls] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	};

	const handleQuerySubmit = async () => {
		if (!selectedDirectory) {
			console.error("No directory selected");
			setError("No directory selected");
			return;
		}

		setLoading(true);
		setError(null);

		try {
			const res = await axios.post(
				"https://c6e9xm0t2k.execute-api.us-east-1.amazonaws.com/dev/ask-question",
				{
					query,
					directory: `${selectedDirectory}/final_embedding.pkl`,
					email: userEmail,
				}
			);
			const data = res.data;
			setResponse(data.answer);

			// Set unique citations and presigned URLs
			setCitations(data.citations || []);
			setPresignedUrls(data.presigned_urls || []);

			console.log("Unique Citations:", data.citations); // Log unique citations
			console.log("Unique Presigned URLs:", data.presigned_urls); // Log unique presigned URLs
		} catch (error) {
			console.error("Error fetching response:", error);
			setError("There was an error fetching the response. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const handleClear = () => {
		setQuery("");
		setResponse("");
		setCitations([]);
		setPresignedUrls([]);
		setError(null);
	};

	const formatResponse = (text: string) => {
		return text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
	};

	return (
		<Paper elevation={3} sx={{ p: 2, width: "100%" }}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				gap={2}
				width="100%"
			>
				<TextField
					label="Ask HEMY your question here..."
					variant="outlined"
					fullWidth
					multiline
					rows={4}
					value={query}
					onChange={handleQueryChange}
					disabled={loading}
					sx={{
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: "gray",
							},
							"&:hover fieldset": {
								borderColor: "#B22222",
							},
							"&.Mui-focused fieldset": {
								borderColor: "#B22222",
							},
						},
						"& .MuiInputLabel-root": {
							color: "#B22222",
						},
						"& .MuiInputLabel-root.Mui-focused": {
							color: "#B22222",
						},
					}}
				/>
				<Box display="flex" gap={2}>
					<Button
						variant="contained"
						sx={{
							backgroundColor: "#B22222",
							"&:hover": {
								backgroundColor: "#8B0000",
							},
						}}
						onClick={handleQuerySubmit}
						disabled={loading || query.trim() === ""}
						aria-label="Submit your question"
					>
						{loading ? (
							<CircularProgress size={24} sx={{ color: "#fff" }} />
						) : (
							"Submit"
						)}
					</Button>
					<Button
						variant="contained"
						sx={{
							backgroundColor: "#B22222",
							"&:hover": {
								backgroundColor: "#8B0000",
							},
						}}
						onClick={handleClear}
						disabled={loading}
						aria-label="Clear the query"
					>
						Clear
					</Button>
				</Box>
				{error && (
					<Typography variant="body2" color="error">
						{error}
					</Typography>
				)}
				{response && (
					<Box mt={2} width="100%">
						<Typography
							variant="body1"
							sx={{ whiteSpace: "pre-wrap" }}
							dangerouslySetInnerHTML={{ __html: formatResponse(response) }}
						/>
					</Box>
				)}
				{citations.length > 0 && (
					<Box mt={2} width="100%">
						<Typography variant="h6">Sources</Typography>
						{citations.map((citation, index) => (
							<Typography
								key={index}
								variant="body2"
								sx={{ whiteSpace: "pre-wrap" }}
							>
								<a
									href={presignedUrls[index]}
									target="_blank"
									rel="noopener noreferrer"
								>
									{citation}
								</a>
							</Typography>
						))}
					</Box>
				)}
			</Box>
		</Paper>
	);
};

export default ChatBox;
