import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import { signIn } from "../services/cognitoService";

interface LoginPageProps {
	checkAuthAndTerms: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ checkAuthAndTerms }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const handleLogin = async () => {
		console.log("Attempting login...");
		try {
			const result = await signIn(email, password);
			console.log("Login successful:", result);
			checkAuthAndTerms();
			navigate("/terms"); // Navigate to terms after login
		} catch (err: unknown) {
			console.log("Login failed:", err);
			if (err instanceof Error) {
				setError(
					err.message || "Failed to login. Please check your credentials."
				);
			} else {
				setError("An unexpected error occurred. Please try again.");
			}
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				backgroundColor: "#f5f5f5",
			}}
		>
			<Paper elevation={3} sx={{ p: 4, width: "300px" }}>
				<Typography variant="h5" sx={{ mb: 2 }}>
					Login
				</Typography>
				<TextField
					label="Email"
					variant="outlined"
					fullWidth
					sx={{
						mb: 2,
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: "gray",
							},
							"&:hover fieldset": {
								borderColor: "#B22222",
							},
							"&.Mui-focused fieldset": {
								borderColor: "#B22222",
							},
						},
						"& .MuiInputLabel-root": {
							color: "#B22222",
						},
						"& .MuiInputLabel-root.Mui-focused": {
							color: "#B22222",
						},
					}}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					label="Password"
					variant="outlined"
					type="password"
					fullWidth
					sx={{
						mb: 2,
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: "gray",
							},
							"&:hover fieldset": {
								borderColor: "#B22222",
							},
							"&.Mui-focused fieldset": {
								borderColor: "#B22222",
							},
						},
						"& .MuiInputLabel-root": {
							color: "#B22222",
						},
						"& .MuiInputLabel-root.Mui-focused": {
							color: "#B22222",
						},
					}}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				{error && (
					<Typography color="error" sx={{ mb: 2 }}>
						{error}
					</Typography>
				)}
				<Button
					variant="contained"
					sx={{
						backgroundColor: "#B22222",
						color: "#FFFFFF",
						"&:hover": {
							backgroundColor: "#8B0000",
						},
						mb: 2,
					}}
					fullWidth
					onClick={handleLogin}
				>
					Login
				</Button>
				<Typography variant="body2" sx={{ mt: 2 }}>
					Don't have an account?{" "}
					<Link to="/signup" style={{ color: "#B22222" }}>
						Sign up here
					</Link>
				</Typography>
			</Paper>
		</Box>
	);
};

export default LoginPage;
