import React, { useEffect, useState } from "react";
import {
	Container,
	Typography,
	Paper,
	List,
	ListItem,
	ListItemText,
	Link,
} from "@mui/material";
import { listS3FoldersAndUrls } from "../services/lambdaService"; // Ensure correct import path

const replaceUnderscores = (str: string): string => {
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	str = str.replace(
		/_His_Eminence_Metropolitan_Youssef_/g,
		"(His Eminence Metropolitan Youssef)"
	);

	str = str.replace(/_n_d_/g, ".(n.d.).");

	str = str.replace(/_(?=\d)/g, ". ");

	months.forEach((month) => {
		const regex = new RegExp(`(?<=\\d)_(?=${month})`, "g");
		str = str.replace(regex, ", ");
	});

	str = str.replace(
		/(?<=\d)_(?!January|February|March|April|May|June|July|August|September|October|November|December)/g,
		"."
	);

	months.forEach((month) => {
		const regex = new RegExp(`(?<=${month})_`, "g");
		str = str.replace(regex, ".");
	});

	str = str.replace(/_(?=\.)/g, "").replace(/_/g, " ");

	str = str.replace(/\s+\./, ".");

	months.forEach((month) => {
		const regex = new RegExp(`(\\d)\\.\\s+${month}`, "g");
		str = str.replace(regex, "$1, " + month);
	});

	return str;
};

const SourcesPage: React.FC = () => {
	const [folders, setFolders] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [presignedUrls, setPresignedUrls] = useState<Record<string, string>>(
		{}
	);

	useEffect(() => {
		const fetchFoldersAndUrls = async () => {
			setLoading(true);
			try {
				const response = await listS3FoldersAndUrls();
				console.log("API Response:", response); // Log the API response
				const { folders: fetchedFolders, presigned_urls: fetchedUrls } =
					response;

				// Ensure fetchedFolders is an array and filter unique folders
				const uniqueFolders = Array.isArray(fetchedFolders)
					? Array.from(
							new Set(
								fetchedFolders.filter((folder: string) => folder.trim() !== "")
							)
					  )
					: [];

				uniqueFolders.sort(); // Sort the unique folders

				console.log("Unique and Sorted Folders:", uniqueFolders); // Log unique and sorted folders
				console.log("Presigned URLs:", fetchedUrls); // Log presigned URLs
				setFolders(uniqueFolders);
				setPresignedUrls(fetchedUrls);
			} catch (error) {
				console.error("Error fetching folders and URLs:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchFoldersAndUrls();
	}, []);

	const uniqueCitations = (citations: string[]): string[] => {
		return Array.from(new Set(citations));
	};

	return (
		<Container
			maxWidth="md"
			style={{ marginTop: "16px", marginBottom: "16px" }}
		>
			<Paper
				elevation={3}
				style={{ padding: "16px", maxHeight: "400px", overflowY: "auto" }}
			>
				<Typography variant="h4" gutterBottom>
					Sources
				</Typography>
				{loading ? (
					<Typography variant="body1">Loading...</Typography>
				) : (
					<List>
						{uniqueCitations(folders).map((folder, index) => {
							const presignedUrl = presignedUrls[folder];
							const displayText = `${index + 1}. ${replaceUnderscores(folder)}`;
							console.log(`Folder: ${folder}, Presigned URL: ${presignedUrl}`); // Log folder and URL
							return (
								<ListItem key={index}>
									<ListItemText
										primary={
											<>
												{presignedUrl ? (
													<Link
														href={presignedUrl}
														target="_blank"
														rel="noopener noreferrer"
													>
														{displayText}
													</Link>
												) : (
													displayText
												)}
											</>
										}
									/>
								</ListItem>
							);
						})}
					</List>
				)}
			</Paper>
		</Container>
	);
};

export default SourcesPage;
