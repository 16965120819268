import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import { signUp } from "../services/cognitoService";

const SignUpPage: React.FC = () => {
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const validateEmail = (email: string) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(String(email).toLowerCase());
	};

	const handleSignUp = async () => {
		if (!validateEmail(email)) {
			setError("Invalid email address format.");
			return;
		}

		try {
			await signUp(email, password);
			localStorage.removeItem("termsAccepted"); // Ensure terms are accepted fresh on signup
			navigate(
				"/confirm-signup?email=" +
					encodeURIComponent(email) +
					"&password=" +
					encodeURIComponent(password)
			); // Navigate to confirm-signup after sign-up
		} catch (err: unknown) {
			if (err instanceof Error) {
				setError(
					err.message || "Failed to sign up. Please check your credentials."
				);
			} else {
				setError("An unexpected error occurred. Please try again.");
			}
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				backgroundColor: "#f5f5f5",
			}}
		>
			<Paper elevation={3} sx={{ p: 4, width: "300px" }}>
				<Typography variant="h5" sx={{ mb: 2 }}>
					Sign Up
				</Typography>
				<TextField
					label="Email"
					variant="outlined"
					fullWidth
					sx={{
						mb: 2,
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: "gray",
							},
							"&:hover fieldset": {
								borderColor: "#B22222",
							},
							"&.Mui-focused fieldset": {
								borderColor: "#B22222",
							},
						},
						"& .MuiInputLabel-root": {
							color: "#B22222",
						},
						"& .MuiInputLabel-root.Mui-focused": {
							color: "#B22222",
						},
					}}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					label="Password"
					variant="outlined"
					type="password"
					fullWidth
					sx={{
						mb: 2,
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: "gray",
							},
							"&:hover fieldset": {
								borderColor: "#B22222",
							},
							"&.Mui-focused fieldset": {
								borderColor: "#B22222",
							},
						},
						"& .MuiInputLabel-root": {
							color: "#B22222",
						},
						"& .MuiInputLabel-root.Mui-focused": {
							color: "#B22222",
						},
					}}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				{error && (
					<Typography color="error" sx={{ mb: 2 }}>
						{error}
					</Typography>
				)}
				<Button
					variant="contained"
					sx={{
						backgroundColor: "#B22222",
						color: "#FFFFFF",
						"&:hover": {
							backgroundColor: "#8B0000",
						},
						mb: 2,
					}}
					fullWidth
					onClick={handleSignUp}
				>
					Sign Up
				</Button>
				<Typography variant="body2" sx={{ mt: 2 }}>
					Already have an account?{" "}
					<Link to="/login" style={{ color: "#B22222" }}>
						Login here
					</Link>
				</Typography>
			</Paper>
		</Box>
	);
};

export default SignUpPage;
