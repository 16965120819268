import axios from "axios";
const API_URL = "https://c6e9xm0t2k.execute-api.us-east-1.amazonaws.com/dev"; // Replace with your API Gateway endpoint

export const listS3FoldersAndUrls = async () => {
	try {
		const response = await axios.get(`${API_URL}/list-embeddings`);
		return response.data;
	} catch (error) {
		console.error("Error in listS3FoldersAndUrls:", error);
		throw error;
	}
};
