import React from "react";
import { Container, Typography, Paper } from "@mui/material";

const DedicationPage: React.FC = () => {
	return (
		<Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
			<Paper elevation={3} sx={{ p: 4 }}>
				<Typography variant="h4" gutterBottom></Typography>
				<Typography variant="body1" paragraph>
					First and foremost, I dedicate this work to our Lord and Savior, Jesus
					Christ. It is through His grace and blessing that I have been granted
					the ability and opportunity to contribute to a project aimed at
					glorifying His name.
				</Typography>
				<Typography variant="body1" paragraph>
					This work is also dedicated to His Eminence Metropolitan Youssef,
					whose steadfast guidance and unwavering commitment to shepherding his
					flock have preserved the truth and tradition of the Coptic Orthodox
					Faith for many generations. The <i>Encyclopedia of Christian Q&A</i>{" "}
					by His Eminence Metropolitan Youssef, which I purchased many years ago
					in printed format upon my conversion to Coptic Orthodoxy, served as
					the foundational inspiration for this project.
				</Typography>

				<Typography variant="body1" paragraph>
					I extend my deepest gratitude to the many clergy who have been
					instrumental in my spiritual journey, especially my Father of
					Confession, Fr. Tadros, and Fr. Suriel.
				</Typography>
				{/* First and foremost, I thank His
					Grace Bishop Basil for his spiritual leadership, compassionate
					guidance, and unwavering dedication. I also wish to thank Fr. Tadros,
					my father of confession, whose wisdom and counsel have been
					invaluable. Additionally, I express my heartfelt thanks to Fr. Suriel,
					Fr. Mikhail, Fr. Jerome, and Fr. Timotheus for their love, guidance,
					and support. */}

				<Typography variant="body1" paragraph>
					Lastly, I dedicate this work to my wife and children. To my wife, your
					constant encouragement, dedication, and steadfast faith have been a
					guiding light in our family's spiritual journey. To my children, may
					you always be firmly rooted in the Coptic Orthodox Church and have
					reliable sources of truth in a world that can often be confusing. Your
					presence and future within the Church inspire this work.
				</Typography>
				{/* <Typography variant="body1" paragraph>
					I also wish to acknowledge and thank the many others, including the
					Roots2Fruits Bible Study group, Farid Abdelmalak, and Joseph Nasser,
					who have played a pivotal role in shaping my understanding of
					Orthodoxy, enhancing my experiences within the Church and Coptic
					culture, and always providing Christian advice. To all the monks,
					Bishops, Priests, and Coptic Orthodox brothers and sisters, and to
					anyone I may have inadvertently forgotten, your collective
					contributions have been vital to my journey in Coptic Orthodoxy and
					the realization of this project.
				</Typography> */}
				{/* <Typography variant="body1" paragraph>
					With sincere appreciation,
				</Typography>
				<Typography variant="body1" paragraph>
					Coptic Convert
				</Typography> */}
			</Paper>
		</Container>
	);
};

export default DedicationPage;
