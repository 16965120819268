import React, { useEffect, useState } from "react";
import {
	Routes,
	Route,
	Navigate,
	useLocation,
	Link,
	useNavigate,
} from "react-router-dom";
import {
	Box,
	CssBaseline,
	Container,
	Grid,
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Menu,
	MenuItem,
	Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChatBox from "./components/ChatBox";
import LoginPage from "./components/LoginPage";
import SignUpPage from "./components/SignUpPage";
import ConfirmSignUpPage from "./components/ConfirmSignUpPage";
import DedicationPage from "./components/DedicationPage";
import SourcesPage from "./components/SourcesPage";
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";
import { getCurrentUser, signOut } from "./services/cognitoService";
import logo from "./assets/logo.png";

const App: React.FC = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [authenticated, setAuthenticated] = useState<boolean | null>(null);
	const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const location = useLocation();
	const navigate = useNavigate();

	const checkAuthAndTerms = () => {
		const user = getCurrentUser();
		if (user) {
			user.getSession((err: any, session: any) => {
				if (err || !session.isValid()) {
					setAuthenticated(false);
				} else {
					setAuthenticated(true);
					const termsAccepted = localStorage.getItem("termsAccepted");
					setTermsAccepted(termsAccepted === "true");
					setEmail(user.getUsername());
				}
			});
		} else {
			setAuthenticated(false);
		}
	};

	useEffect(() => {
		checkAuthAndTerms();
	}, []);

	useEffect(() => {
		console.log("Authenticated:", authenticated);
		console.log("Terms Accepted:", termsAccepted);
		console.log("Current Path:", location.pathname);

		if (
			authenticated &&
			!termsAccepted &&
			location.pathname !== "/terms" &&
			location.pathname !== "/login" &&
			location.pathname !== "/signup" &&
			location.pathname !== "/confirm-signup"
		) {
			console.log("Navigating to terms page...");
			navigate("/terms");
		}
	}, [authenticated, termsAccepted, location.pathname, navigate]);

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSignOut = () => {
		console.log("Signing out...");
		signOut();
		localStorage.removeItem("termsAccepted");
		setAuthenticated(false);
		setTermsAccepted(false);
		navigate("/login");
	};

	const selectedDirectory = "hemy";

	const showHeader =
		location.pathname !== "/login" &&
		location.pathname !== "/signup" &&
		location.pathname !== "/terms" &&
		location.pathname !== "/confirm-signup";

	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
			<CssBaseline />
			{showHeader && (
				<AppBar
					position="static"
					sx={{
						backgroundColor: "#FFFFFF",
						color: "#000000",
						borderBottom: "2px solid #B22222",
					}}
				>
					<Toolbar sx={{ justifyContent: "space-between" }}>
						<Link
							to="/"
							style={{ textDecoration: "none", color: "inherit", flex: 1 }}
						>
							<Typography variant="h6">Ask HEMY</Typography>
						</Link>
						<Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
							<img src={logo} alt="Ask HEMY Logo" style={{ height: "40px" }} />
						</Box>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="menu"
							onClick={handleMenu}
							sx={{
								flex: 1,
								justifyContent: "flex-end",
								"&:hover": {
									backgroundColor: "transparent",
								},
							}}
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(anchorEl)}
							onClose={handleClose}
							sx={{
								"& .MuiPaper-root": {
									backgroundColor: "#FFFFFF",
									color: "#000000",
								},
							}}
						>
							<MenuItem
								component={Link}
								to="/"
								onClick={handleClose}
								sx={{
									backgroundColor: "#FFFFFF",
									color: "#000000",
									"&:hover": {
										backgroundColor: "#B22222",
										color: "#00000",
									},
								}}
							>
								Ask HEMY
							</MenuItem>
							<MenuItem
								component={Link}
								to="/sources"
								onClick={handleClose}
								sx={{
									backgroundColor: "#FFFFFF",
									color: "#000000",
									"&:hover": {
										backgroundColor: "#B22222",
										color: "#00000",
									},
								}}
							>
								Sources
							</MenuItem>
							<MenuItem
								component={Link}
								to="/dedication"
								onClick={handleClose}
								sx={{
									backgroundColor: "#FFFFFF",
									color: "#000000",
									"&:hover": {
										backgroundColor: "#B22222",
										color: "#00000",
									},
								}}
							>
								Dedications
							</MenuItem>

							<MenuItem
								onClick={() => {
									handleSignOut();
									handleClose();
								}}
								sx={{
									backgroundColor: "#FFFFFF",
									color: "#000000",
									"&:hover": {
										backgroundColor: "#B22222",
										color: "#000000",
									},
								}}
							>
								Sign Out
							</MenuItem>
						</Menu>
					</Toolbar>
				</AppBar>
			)}
			<Routes>
				<Route path="/signup" element={<SignUpPage />} />
				<Route
					path="/login"
					element={<LoginPage checkAuthAndTerms={checkAuthAndTerms} />}
				/>
				<Route path="/confirm-signup" element={<ConfirmSignUpPage />} />
				<Route path="/dedication" element={<DedicationPage />} />
				<Route path="/sources" element={<SourcesPage />} />
				<Route
					path="/terms"
					element={
						<TermsAndConditionsPage checkAuthAndTerms={checkAuthAndTerms} />
					}
				/>
				<Route
					path="/"
					element={
						authenticated && termsAccepted ? (
							<Container maxWidth="lg" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<ChatBox
											selectedDirectory={selectedDirectory}
											userEmail={email}
										/>
									</Grid>
								</Grid>
							</Container>
						) : (
							<Navigate to="/login" />
						)
					}
				/>
				<Route path="*" element={<Navigate to="/login" />} />
			</Routes>
			<Paper
				component="footer"
				square
				sx={{
					p: 2,
					mt: "auto",
					backgroundColor: "#FFFFFF",
					color: "#000000",
					borderTop: "2px solid #B22222",
				}}
			>
				<Container maxWidth="lg">
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						height="100%"
					>
						<Typography variant="body1">© 2024 Ask HEMY</Typography>
						<Link
							to="/terms"
							style={{
								color: "#B22222",
								textDecoration: "none",
								marginTop: "8px",
							}}
						>
							Terms and Conditions
						</Link>
					</Box>
				</Container>
			</Paper>
		</Box>
	);
};

export default App;
